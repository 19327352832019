import { render, staticRenderFns } from "./monitorComponent.vue?vue&type=template&id=32b7f6f1&scoped=true&"
import script from "./monitorComponent.vue?vue&type=script&lang=js&"
export * from "./monitorComponent.vue?vue&type=script&lang=js&"
import style0 from "./monitorComponent.vue?vue&type=style&index=0&id=32b7f6f1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b7f6f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VFadeTransition,VProgressCircular,VSheet})
