<template>
    <monitor-component></monitor-component>
</template>

<script>
import monitorComponent from '../components/monitor/monitorComponent.vue'

export default {
  components: { monitorComponent },

}
</script>

<style>

</style>