<template>
  <v-container fluid class="wrapper">
    <v-card width="500">
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col :cols="$vuetify.breakpoint.xs ? '12' : '9'" 
            ><v-text-field
              dense
              hide-details="auto"
              color="success"
              prepend-icon="mdi-account-key-outline"
              v-model.trim="inputKey"
            >
            </v-text-field>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? '12' : '3'" :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
            ><v-btn @click="onLogin" :disabled="!inputKey" color="success" :class="$vuetify.breakpoint.xs ? 'w-100' : ''">Enter</v-btn></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import EventBus from "@/events/EventBus";

export default {
  mixins: [EventBus],
  data: () => ({
    inputKey: ""
  }),
  methods: {
    onLogin() {
      EventBus.$emit("on-login", this.inputKey);
    }
  }
};
</script>

<style></style>
