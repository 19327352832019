<template>
  <div class="my-wrapper">
    <v-progress-linear
      v-if="$vuetify.breakpoint.smAndDown"
      color="green lighten-1"
      v-model="progressValue"
      @click="onReload()"
    ></v-progress-linear>
  <div class="content-data">
    <div class="header justify-content-space-between">
      <div v-if="!$vuetify.breakpoint.smAndDown" @click="onReload()" class="btn-reload">
        <v-progress-circular
          :size="20"
          :width="2"
          color="green lighten-1"
          :value="progressValue"
        ></v-progress-circular>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown && monitorData.deport_speed" class="speedometer-mobile">
        <div class="speedometer-mobile-content" :style="`color: ${ getSpeedometerColor }`">
          <speedometer-icon :fill="getSpeedometerColor" />
          <div class="speedometer-mobile-content-num">
            {{ Number(monitorData.deport_speed) }}
          </div>
        </div>
        <div class="d-flex align-center">
          <div>
            <v-icon>mdi-sigma</v-icon>
          </div>
          <div>
            {{ totalDeliveries }}
          </div>
        </div>
      </div>
      <div class="logo d-flex align-center justify-content-between">
        <div>
          <img style="height: 6vh" class="logo__img" :src="require('@/assets/images/newsend_new_logo_light.svg')"/>
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <flag style="height: 5vh; width: 8vh" class="flag"></flag>
        </div>
      </div>
      <div class="btn-logout" @click="onLogOut">
        <v-btn icon>
          <v-icon dark color="grey lighten-2">mdi-logout</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="message-content">
      <div class="rectangle pl-3">
        <v-icon :style="`color: ${messageColor}; font-size: 2vh; `">{{ messageIcon }}</v-icon>
        <div style="font-size: 2.5vh" :style="`color: ${messageColor}; max-width: 99%; word-wrap: break-word;`" class="text ml-5">
          {{ warningMeassge }}
        </div>
      </div>

    </div>
    <div class="main-content">
      <div class="main-content-deliveries" ref="main-content">
        <div class="amount-text">
          <div
            class="info-item"
          >
            <div style="font-size: 2.5vh" class="info-item-text no-wrap"   >
              Очікує відправки
            </div>
            <div class="info-item-analytic"></div>
          </div>
          <div
            class="amount-item"
          >
            <span
              class="amount-item-text__total"
            >
              {{ totalConfirmedAndReady }}
            </span>
            <div class="amount-item-img__total">
              <img style="height: 4vh" class="item__img" :src="require('@/assets/images/icon_box_ready_to_delivery.svg')" />
            </div>
          </div>
        </div>
        <div class="amount-text">
          <div
            class="info-item"
          >
            <div style="font-size: 2.5vh" class="info-item-text no-wrap"    >
              Готово до відправки
            </div>
            <div class="info-item-analytic"></div>
          </div>
          <div
            class="amount-item"
          >
            <span
              class="amount-item-text__ready"
            >
              {{ monitorData.ready_to_deliver }}
            </span>
            <div class="amount-item-img__ready">
              <img style="height: 4vh" class="item__img" :src="require('@/assets/images/icon_leaf_awaiting shipment.svg')" />
            </div>
          </div>
        </div>
        <div class="amount-text">
          <div
            class="info-item"
          >
            <div style="font-size: 2.5vh" class="info-item-text no-wrap"   
            >
              Відправлено клієнту
            </div>
            <div class="info-item-analytic"></div>
          </div>
          <div
            class="amount-item"
          >
            <span
                 class="amount-item-text__send"
            >
              {{ monitorData.send }}
            </span>
            <div class="amount-item-img__send">
              <img style="height: 4vh" class="item__img" :src="require('@/assets/images/icon_track_send_to_client.svg')" />
            </div>
          </div>
        </div>
      </div>
      <div class="main-content-return">
        <div class="return-content">
          <div class="return-content-title">
            <div style="font-size: 2.5vh" class="return-title"> {{ 'Повернення' }} </div>
            <div></div>
          </div>
          <div class="about">
            <div class="about-packed">
              <div class="packed-circle mt-3"></div>
              <div class="packed-info">
                <div style="font-size: 4vh" class="packed-info-percent">
                  {{`${Math.round(returnedPercentPacked)} %`  }}
                </div>
                <div style="font-size: 2vh" class="packed-info-text">
                  {{ 'Опрацьовано: ' }} {{ monitorData.return_packed }}
                </div>
              </div>
            </div>
            <div class="about-closed">
              <div class="closed-circle mt-3"></div>
              <div class="closed-info">
                <div style="font-size: 4vh" class="closed-info-percent">
                  {{`${Math.round(returnedPercentClosed)} %`  }}
                </div>
                <div style="font-size: 2vh" class="closed-info-text">
                  {{ 'Очікує закриття: ' }} {{ monitorData.return_np_closure }}
                </div>
              </div>
            </div>
            <div class="about-delivered">
              <div class="delivered-circle mt-3"></div>
              <div class="delivered-info">
                <div style="font-size: 4vh" class="delivered-info-percent">
                  {{ `${Math.round(returnedPercentDelivered)} %`}}
                </div>
                <div style="font-size: 2vh" class="delivered-info-text">
                  {{ 'Отримано: ' }} {{ monitorData.return_delivered }}
                </div>
              </div>
            </div>
          </div>
          <div class="return-progress-linear" style="width: 100%; position: relative;">
            <v-progress-linear
              v-model="returnedPercentPacked"
              :buffer-value="returnedPercentPacked"
              color="#0950A0"
              stream
              style="position: absolute; left: 0; top: 0; width: 100%; height: 4vh; pointer-events: none"
            ></v-progress-linear>
            <v-progress-linear
              v-model="returnedPercentDelivered"
              :buffer-value="returnedPercentDelivered + returnedPercentClosed"
              color="#D74800"
              buffer-color="#A1A1A1"
              background-color="#556B2F"
              reverse
              style="position: absolute; left: 0; top: 0; width: 100%; height: 4vh; pointer-events: none"
            ></v-progress-linear>
          </div>
        </div>
        <div v-if="monitorData.deport_speed && !$vuetify.breakpoint.smAndDown" class="speedometer-content" :style="`height: ${ fontSizeCounter }`">
          <div class="speedometer-title-content">
            <div style="font-size: 2.5vh" class="speedometer-title"> {{ 'Швидкість' }} </div>
            <div></div>
          </div>
          <div class="speedometer-item"> <speedometer v-if="monitorData.deport_speed" :speed="monitorData.deport_speed" :size="fontSizeCounterTextMobile" style="min-width: 5vh" class="pb-7" :percentage="Number(monitorData.deport_speed)"/> </div>
        </div>
      </div>
    </div>
    <div v-if="!$vuetify.breakpoint.smAndDown" class="footer justify-content-space-between">
      <div class="sum-content">
        <div>
          <v-icon style="font-size: 6vh">mdi-sigma</v-icon>
        </div>
        <div style="font-size: 5vh">
          {{ totalDeliveries }}
      </div>
      </div>
      <div class="clock">
        <img style="height: 5vh" :src="require('@/assets/images/icon_clock.svg')" />
        <DigitalClock
          style="font-size: 5vh"
        />
      </div>
    </div>
   </div>
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import DigitalClock from "@/assets/clock/DigitalClock.vue";
import Flag from "@/assets/flag/ukrainianFlag.vue";
import Speedometer from "@/assets/speedometer/speedometer.vue";
import SpeedometerIcon from "@/components/icons/Speedometer.vue";
import user from "@/mixins/user";

export default {
  props: {
    monitorData: {
      required: true,
    },
    seconds: {
      required: true,
    },
  },
  data: () => ({
    returnedPerDel: "",
    returnedPerPack: "",
    totalDelivered: "",
    totalPacked: "",
    totalClosed: ""
  }),
  methods: {
    setProportionalFontSize(){
      const items = this.$refs['main-content'].querySelectorAll('.amount-text')

      items.forEach(item => {
        const parentWidth = item.clientWidth;
        const baseFontSize = 22; 
        const scaleFactor = 0.05;
        const fontSize = Math.min(baseFontSize + parentWidth * scaleFactor, 40);
        const textElement = item.querySelector('.amount-item')
        textElement.style.fontSize = fontSize + 'vh';
      })
    },
    onLogOut() {
      EventBus.$emit("on-logout");
    },
    onReload() {
      EventBus.$emit("on-reload");
    },
  },
  components: {
    SpeedometerIcon,
    Speedometer,
    Flag,
    DigitalClock: DigitalClock,
  },
  mixins: [user],
  mounted() {
    this.setProportionalFontSize();
    window.addEventListener('resize', this.setProportionalFontSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setProportionalFontSize);
  },
  computed: {
    progressValue() {
      return 100 - ((this.seconds * 5) / 3);
    },
    totalConfirmedAndReady() {
      const confirmed = parseInt(this.monitorData.confirmed, 10);
      const ready = parseInt(this.monitorData.ready_for_packing, 10);
      return confirmed + ready;
    },
    totalDeliveries() {
      const confirmed = parseInt(this.monitorData.confirmed, 10);
      const readyPack = parseInt(this.monitorData.ready_for_packing, 10);
      const readyDel = parseInt(this.monitorData.ready_to_deliver, 10);
      const send = parseInt(this.monitorData.send, 10);
      return confirmed + readyPack + readyDel + send;
    },
    fontSizeCounter() {
      if(window.innerWidth > 900){
        return 16 - (this.fontLengthCounter) + 'vw';
      }
      return 120 + 'px';
    },
    fontLengthCounter(){
      const maxLength = Math.max(this.totalConfirmedAndReady.toString().length, this.monitorData.ready_to_deliver.toString().length, this.monitorData.send.toString().length)
      return maxLength;
    },
    fontSizeCounterTextMobile() {
      return window.innerWidth - (window.innerWidth / 100) * 97
    },
    getSpeedometerColor() {
      const speed = Number(this.monitorData.deport_speed)
      if (speed < 34) {
        return '#4FAE3B';
      } else if (speed >= 34 && speed <= 68) {
        return '#FBC400';
      } else {
        return '#D74800';
      }
    },
    returnedPercentDelivered() {
      if (this.monitorData.return_delivered !== '0') {
        const sum = parseInt(this.monitorData.return_delivered, 10) + parseInt(this.monitorData.return_packed, 10) + parseInt(this.monitorData.return_np_closure, 10);
        this.totalDelivered = ((parseInt(this.monitorData.return_delivered, 10) / sum) * 100).toFixed(2);
        return parseFloat(this.totalDelivered);
      } else {
        return 0;
      }
    },
    returnedPercentPacked() {
      if (this.monitorData.return_packed !== '0') {
        const sum = parseInt(this.monitorData.return_delivered, 10) + parseInt(this.monitorData.return_packed, 10) + parseInt(this.monitorData.return_np_closure, 10);
        this.totalPacked = ((parseInt(this.monitorData.return_packed, 10) / sum) * 100).toFixed(2);
        return parseFloat(this.totalPacked);
      } else {
        return 0;
      }
    },
    returnedPercentClosed() {
      if (this.monitorData.return_np_closure !== '0') {
        const sum = parseInt(this.monitorData.return_delivered, 10) + parseInt(this.monitorData.return_packed, 10) + parseInt(this.monitorData.return_np_closure, 10);
        this.totalClosed = ((parseInt(this.monitorData.return_np_closure, 10) / sum) * 100).toFixed(2);
        return parseFloat(this.totalClosed);
      } else {
        return 0;
      }
    },
    messageColor() {
      if(this.monitorData.error) {
        return '#D74800';
      } else if(this.monitorData.warning) {
        return '#FBC400';
      } else if(this.monitorData.message) {
        return '#4FAE3B';
      }
      return '';
    },
    messageIcon() {
      if(this.monitorData.error) {
        return 'mdi-alert-outline'
      } else if(this.monitorData.warning) {
        return 'mdi-alert-circle'
      } else if(this.monitorData.message) {
        return 'mdi-message-text'
      }
      return '';
    },
    warningMeassge() {
      if(this.monitorData.error) {
        return this.monitorData.error
      } else if(this.monitorData.warning) {
        return this.monitorData.warning
      } else if(this.monitorData.message) {
        return this.monitorData.message
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100%;
}

.my-wrapper {
  min-height: 100vh;
  position: relative;
}
.content-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background: #fff;
  height: calc(100vh);
  overflow-y: auto;
}
.header {
  display: flex;
  align-items: center;
  align-self: stretch;

  .btn-reload {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;
  }

  .speedometer-mobile {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;

    .speedometer-mobile-content {
      display: flex;
      align-items: center;
      gap: 8px;

      .speedometer-mobile-content-num {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

    }

  }

  .btn-logout {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;
  }

}

.message-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.rectangle {
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 19px;
}

.text {
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.main-content-deliveries {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
}
.main-content-return {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;

  .return-content {
    display: flex;
    padding: 24px;
    min-height: 250px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #F2F4F5;
    background: #FFF;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

    .return-content-title {
      display: flex;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .return-title {
        color: var(--bl-text, #1D1D1D);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

    }

    .about {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .about-packed {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .packed-circle {
          display: flex;
          width: 2.5vh;
          height: 2.5vh;
          padding: 1vh;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 1vh;
          background: #0950A0;
        }
        .packed-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          .packed-info-percent {
            color: #1D1D1D;
            leading-trim: both;
            text-edge: cap;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .packed-info-text {
            color: var(--grey-text, #666);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

        }

      }
      .about-closed {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .closed-circle {
          display: flex;
          width: 2.5vh;
          height: 2.5vh;
          padding: 1vh;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 1vh;
          background: #556B2F;
        }
        .closed-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          .closed-info-percent {
            color: #1D1D1D;
            leading-trim: both;
            text-edge: cap;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .closed-info-text {
            color: var(--grey-text, #666);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

        }

      }
      .about-delivered {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .delivered-circle {
          display: flex;
          width: 2.5vh;
          height: 2.5vh;
          padding: 1vh;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 1vh;
          background: #D74800;
        }
        .delivered-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          .delivered-info-percent {
            color: #1D1D1D;
            leading-trim: both;
            text-edge: cap;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .delivered-info-text {
            color: var(--grey-text, #666);
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

        }

      }
        .return-progress-linear {
          height: 20px;
          position: relative;
        }
    }

  }
  .speedometer-content {
    display: flex;
    max-width: calc(33.9% - 20px);;
    padding: 32px 24px;
    min-height: 250px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #F2F4F5;
    background: #F5F5F5;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

    .speedometer-title-content {
      display: flex;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .speedometer-title {
        color: var(--bl-text, #1d1d1d);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

      }

    }
    .speedometer-item {
      width: 63%;
    }

  }

}
.amount-text {
  display: flex;
  padding: 18px;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #f2f4f5;
  background: var(--w, #fff);
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.info-item {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.info-item-text {
  color: var(--bl-text, #1d1d1d);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.info-item-analytic {
  color: var(--gr, #4fae3b);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.amount-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.amount-item-text__total,
.amount-item-text__ready,
.amount-item-text__send {
  font-size: 10vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.amount-item-text__total {
  color: #0950a0;
}
.amount-item-text__ready {
  color: #d74800;
}
.amount-item-text__send {
  color: #4fae3b;
}
.amount-item-img__total,
.amount-item-img__ready,
.amount-item-img__send {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
}
.amount-item-img__total {
  background: rgba(9, 80, 160, 0.1);
}
.amount-item-img__ready {
  background: rgba(215, 72, 0, 0.1);
}
.amount-item-img__send {
  background: rgba(79, 174, 59, 0.1);
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: auto;

  .sum-content {
    display: flex;
    padding: 24px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;

    .sum-title {
      color: var(--bl-text, #1D1D1D);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    .sum-amount {
      color: #4FAE3B;
      leading-trim: both;
      text-edge: cap;
      font-family: Roboto;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

  }

  .clock {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;
  }
}

@media (max-width: 900px) {
  .content-data {
    padding: 10px;
    gap: 10px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .main-content-deliveries {
    gap: 5px;
  }

  .amount-text {
    min-width: 220px;
    width: 100%;
  }

}
@media (max-width: 630px) {
  .amount-text {
    min-width: 200px;
    width: 100%;
  }
}
@media(max-width: 450px){
  .logo img{
    max-height: 40px;
  }
  .flag{
    max-height: 40px;
    max-width: 70px;
  }
  .main-content-return {
    .return-content {
      .about {
        .about-delivered {
          .delivered-info {
            .delivered-info-text {
              font-size: 14px;
            }
          }
        }
        .about-packed {
          .packed-info {
            .packed-info-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.no-wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
